<template>
    <!-- <div style="background-color:#fffde5"> -->
    <div class="pb-4 body">
        <nav class="navbar pt-2 pb-2 pl-5 pr-5  navbar-expand-lg navbar-light text-light sbg-blue">
        <img width="150" src="../assets/logoussnewnotext.png"/>
        <ul class="navbar-nav ml-auto" style="margin-top:-3px">
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/homepage')">Beranda</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/transaction')">Status Pembelian</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/administration')">Hasil Try Out</a>
            </li>
            <li class="nav-item dropdown">
                <a style="text-transform: capitalize;" class="sbn-navbar-invert dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hai, {{display.fullName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/profile')">Profil</a>
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/');removeAnyStoredInformation()">Keluar</a>
                </div>
            </li>
        </ul>
        </nav>
        
        <div style="height:470px" class="mt-4" :style="{ backgroundImage: 'url(' + require('@/assets/header4.jpg') + ')' }">
            <div class="container">
                <div class="row">
                    <div class="col-7">
                        <div class="card srd-25 p-3">
                            <span class="st-blue-title" style="text-align:center">Profil</span>
                            <hr/>
                            <b-tabs class="nav-pills">
                                <b-tab v-for="d in profileSegmentTab" :key="d.profileSegmentTabOrder">
                                <template v-slot:title>
                                    {{d.profileSegmentTabDescription}}
                                </template>
                                <form @submit.prevent="updateProfile()">
                                    <div class="mt-3" style="z-index:1001 !important;">
                                        <div v-show="d.profileSegmentTabCode=='SELF'">
                                            <div class="pl-3 pr-3 wrap-info">
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Nama Lengkap
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <input v-model="form.fullName" type="text">
                                                    
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Nama Panggilan
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <input v-model="form.nickName" type="text">
                                                    
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Tanggal Lahir
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <input v-model="form.birthDate" type="date">
                                                    
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Jenis Kelamin
                                                    </div>
                                                    <div class="col-8 stxb">
                                                        <b-form-select class="bv-input" v-model="form.gender"  :options="genderData"></b-form-select>
                                                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        No Hp | +62
                                                    </div>
                                                    <div class="col-8 stxb">
                                                        <input v-model="form.phone" type="text">
                                                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Instagram | @
                                                    </div>
                                                    <div class="col-8 stxb">
                                                        <input v-model="form.instagram" type="text">
                                                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Jumlah Follower
                                                    </div>
                                                    <div class="col-8 stxb">
                                                        <input v-model="form.follower" type="text">
                                                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Foto Profil
                                                    </div>
                                                    <div class="col-8 stxb">
                                                        <input disabled v-model="form.photo" type="text">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="d.profileSegmentTabCode=='EDUCATION'">
                                            <div class="pl-3 pr-3 wrap-info">
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Provinsi
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-on:change="getCity();" v-model="form.provinceID" :options="provinceData"></b-form-select>
                                                    
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Kota/Kabupaten
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-on:change="getSchool()" v-model="form.cityID" :options="cityData"></b-form-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Nama Sekolah
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-model="form.schoolID" :options="schoolData"></b-form-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Kelas
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-model="form.classID" :options="classData"></b-form-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Jurusan
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-model="form.departmentID" :options="departmentData"></b-form-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Tahun Lulus
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-input class="bv-input" v-model="form.finishYear"></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="d.profileSegmentTabCode=='UNIVERSITY'">
                                            <div class="pl-3 pr-3 wrap-info">
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Perguruan Tinggi (Saintek)
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-on:change="getStudyProgram()" v-model="form.universityID" :options="universityData"></b-form-select>
                                            
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Program Studi (Saintek)
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-model="form.studyProgramID" :options="studyProgramData"></b-form-select>
                                            
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Perguruan Tinggi (Soshum)
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-on:change="getSecondaryStudyProgram()" v-model="form.secondaryUniversityID" :options="secondaryUniversityData"></b-form-select>
                                            
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Program Studi (Soshum)
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select class="bv-input" v-model="form.secondaryStudyProgramID" :options="secondaryStudyProgramData"></b-form-select>
                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="d.profileSegmentTabCode=='VOUCHER'">
                                            <div class="pl-3 pr-3 wrap-info">
                                                <div class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Punya Voucher USS?
                                                    </div>
                                                    <div class="col-8 stxb" >
                                                        <b-form-select v-on:change="refreshVoucherCode();" class="bv-input" v-model="form.hasVoucher" :options="hasVoucherData"></b-form-select>
                                            
                                                    </div>
                                                </div>
                                                <div v-show="form.hasVoucher==1" class="row">
                                                    <div class="col-4 spnd-blue">
                                                        Masukan Kode
                                                    </div>
                                                    <div class="col-8 stxb" >
                                            
                                                        <input v-model="form.voucherCode" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="btn sbtn-orange mt-2 p-2" type="submit" style="border-radius:10px;width:100%;font-size:15px;font-weight:bold">Simpan Data Profil</button>
                                        
                                    </div>
                                </form>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="modal"
            ref="modal"
            title="Update Data"
            hide-footer
            centered
            >
            {{modalMessage}}
        </b-modal>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            testa: '',
            display: {
                fullName: ''
            },
            modalMessage: '',
            dataRuntime: 1,
            userData: [],
            universityData: [],
            secondaryUniversityData: [],
            studyProgramData: [],
            secondaryStudyProgramData: [],
            provinceData: [],
            cityData: [],
            schoolData: [],
            classData: [],
            departmentData: [],
            hasVoucherData: [
                {
                    value: 0,
                    text: 'Tidak, Saya tidak punya'
                },
                {
                    value: 1,
                    text: 'Iya, Saya punya kode khusus Voucher USS'
                }
            ],
            genderData: [
                {
                    value: 'Pria',
                    text: 'Pria'
                },
                {
                    value: 'Wanita',
                    text: 'Wanita'
                }
            ],
            form: {
                fullName: '',
                nickName: '',
                gender: '',
                birthDate: null,
                phone: null,
                instagram: null,
                follower: null,
                photo: null,
                provinceID: null,
                cityID: null,
                school: '',
                schoolID: null,
                classID: null,
                departmentID: null,
                finishYear: null,
                universityID: null,
                secondaryUniversityID: null,
                studyProgramID: null,
                secondaryStudyProgramID: null,
                hasVoucher: null,
                voucherCode: ''
            },
            profileSegmentTab: [
                {
                    profileSegmentTabOrder: 1,
                    profileSegmentTabCode: 'SELF',
                    profileSegmentTabDescription: '1. Data Diri'
                },
                {
                    profileSegmentTabOrder: 2,
                    profileSegmentTabCode: 'EDUCATION',
                    profileSegmentTabDescription: '2. Asal Sekolah'
                },
                {
                    profileSegmentTabOrder: 3,
                    profileSegmentTabCode: 'UNIVERSITY',
                    profileSegmentTabDescription: '3. PTN Impianmu'
                },
                {
                    profileSegmentTabOrder: 4,
                    profileSegmentTabCode: 'VOUCHER',
                    profileSegmentTabDescription: '4. Kode Voucher'
                }
            ],
        };
    },
    afterCreate() {
        let userID = localStorage.getItem("userID");
        if(localStorage.getItem("userID") == null){
            this.$router.push("/");
        }
    },
    created() {
        let userID = localStorage.getItem("userID");
        axios
        .get("https://backend-external.uss-utbk.com/userinformation",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            this.userData = res.data.values;
            let userData = res.data.values;
            this.display.fullName = userData[0].userFullName;
            this.form.fullName = userData[0].userFullName;
            this.form.nickName = userData[0].userNickName;
            this.form.gender = userData[0].userGender;
            if(userData[0].userBirthDate == '0000-00-00'){
                let today = new Date();
                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                this.form.birthDate = date;
            }
            else {
                this.form.birthDate = userData[0].userBirthDate.substring(0,10);
            }
            this.form.phone = userData[0].userPhone;
            this.form.instagram = userData[0].userInstagram;
            this.form.follower = userData[0].userFollower;
            this.form.photo = userData[0].userPhotoProfile;
            this.form.provinceID = userData[0].provinceID;
            this.form.cityID = userData[0].cityID;
            this.form.schoolID = userData[0].schoolID;
            this.form.school = userData[0].userSchool;
            this.form.classID = userData[0].classID;
            this.form.departmentID = userData[0].departmentID;
            this.form.finishYear = userData[0].userFinishYear;
            this.form.universityID = userData[0].universityID;
            this.form.studyProgramID = userData[0].studyProgramID;
            this.form.secondaryUniversityID = userData[0].secondaryUniversityID;
            this.form.secondaryStudyProgramID = userData[0].secondaryStudyProgramID;
            this.form.hasVoucher = userData[0].userHasVoucher;
            this.form.voucherCode = userData[0].userVoucherCode;
            this.getCity();
            this.getSchool();
            this.getStudyProgram();
            this.getSecondaryStudyProgram();
        });
        axios
        .get("https://backend-external.uss-utbk.com/n-universitysaintekdropdown")
        .then(res => {
            this.universityData = res.data.values;
        });
        axios
        .get("https://backend-external.uss-utbk.com/n-universitysoshumdropdown")
        .then(res => {
            this.secondaryUniversityData = res.data.values;
        });
        axios
        .get("https://backend-external.uss-utbk.com/provincedropdown")
        .then(res => {
            this.provinceData = res.data.values;
        });
        axios
        .get("https://backend-external.uss-utbk.com/classdropdown")
        .then(res => {
            this.classData = res.data.values;
        });
        axios
        .get("https://backend-external.uss-utbk.com/departmentdropdown")
        .then(res => {
            this.departmentData = res.data.values;
        });
    },
    methods: {
        refreshVoucherCode() {
            let formData = this.form;
            let ref = this;
            if(formData.hasVoucher==0){
                ref.form.voucherCode = null;
            }
            console.log(formData.voucherCode)
        },
        openModal(){
            this.$refs['modal'].show()
        },
        closeModal(){
            this.$refs['modal'].hide()
        },
        removeAnyStoredInformation(){
            localStorage.removeItem("userID");
            localStorage.removeItem("userPackageID");
            localStorage.removeItem("packageID");
        },
        updateProfile(evt){
            let userID = localStorage.getItem("userID");
            let phonePrefix = '0';
            let instagramPrefix = '@';
            let formData = this.form;
            let object = this;
            if(formData.hasVoucher==1){
                axios
                .get("https://backend-external.uss-utbk.com/n-voucherbyvouchercode",{
                    params: {
                        voucherCode: formData.voucherCode
                    }
                })
                .then(res => {
                    let data = res.data.values;
                    let totalCode = data.length;
                    if(totalCode>0){
                        axios.put('https://backend-external.uss-utbk.com/n-updatevouchertimebyvouchercode', {
                            voucherCode: formData.voucherCode
                        })
                        .then(function (response) {
                            object.output = response.data;

                        })
                        .catch(function (error) {
                            object.output = error;
                        });
                        axios.put('https://backend-external.uss-utbk.com/userinformation', {
                            userID: userID,
                            fullName: formData.fullName,
                            nickName: formData.nickName,
                            gender: formData.gender,
                            birthDate: formData.birthDate,
                            phone: phonePrefix.concat(formData.phone),
                            instagram: instagramPrefix.concat(formData.instagram),
                            follower: formData.follower,
                            photo: formData.photo,
                            provinceID: formData.provinceID,
                            cityID: formData.cityID,
                            schoolID: formData.schoolID,
                            classID: formData.classID,
                            departmentID: formData.departmentID,
                            finishYear: formData.finishYear,
                            universityID: formData.universityID,
                            studyProgramID: formData.studyProgramID,
                            secondaryUniversityID: formData.secondaryUniversityID,
                            secondaryStudyProgramID: formData.secondaryStudyProgramID,
                            hasVoucher: formData.hasVoucher,
                            voucherCode: formData.voucherCode
                        })
                        .then(function (response) {
                            object.output = response.data;

                        })
                        .catch(function (error) {
                            object.output = error;
                        });
                        console.log(formData.secondaryUniversityID);
                        console.log(formData.secondaryStudyProgramID);
                        this.display.fullName = formData.fullName;
                        this.modalMessage = 'Data Diri Berhasil Terupdate';
                        this.openModal();
                    }
                    else {
                        this.modalMessage = 'Kode Voucher Tidak Sesuai';
                        this.openModal();
                    }
                });
            }
            else {
                axios.put('https://backend-external.uss-utbk.com/userinformation', {
                    userID: userID,
                    fullName: formData.fullName,
                    nickName: formData.nickName,
                    gender: formData.gender,
                    birthDate: formData.birthDate,
                    phone: phonePrefix.concat(formData.phone),
                    instagram: instagramPrefix.concat(formData.instagram),
                    follower: formData.follower,
                    photo: formData.photo,
                    provinceID: formData.provinceID,
                    cityID: formData.cityID,
                    schoolID: formData.schoolID,
                    classID: formData.classID,
                    departmentID: formData.departmentID,
                    finishYear: formData.finishYear,
                    universityID: formData.universityID,
                    studyProgramID: formData.studyProgramID,
                    secondaryUniversityID: formData.secondaryUniversityID,
                    secondaryStudyProgramID: formData.secondaryStudyProgramID,
                    hasVoucher: formData.hasVoucher,
                    voucherCode: formData.voucherCode
                })
                .then(function (response) {
                    object.output = response.data;

                })
                .catch(function (error) {
                    object.output = error;
                });
                this.display.fullName = formData.fullName;
                this.modalMessage = 'Data Diri Berhasil Terupdate';
                this.openModal();
            }
            
            
        },
        getStudyProgram(){
            let universityID = this.form.universityID;
            axios
            .get("https://backend-external.uss-utbk.com/n-studyprogramdepartmentuniversity",{
                params: {
                    universityID: universityID,
                    departmentID: 1
                }
            })
            .then(res => {
                this.studyProgramData = res.data.values;
            });
        },
        getSecondaryStudyProgram(){
            let universityID = this.form.secondaryUniversityID;
            axios
            .get("https://backend-external.uss-utbk.com/n-studyprogramdepartmentuniversity",{
                params: {
                    universityID: universityID,
                    departmentID: 2
                }
            })
            .then(res => {
                this.secondaryStudyProgramData = res.data.values;
            });
        },
        getCity(){
            let provinceID = this.form.provinceID;
            axios
            .get("https://backend-external.uss-utbk.com/citydropdown",{
                params: {
                    provinceID: provinceID
                }
            })
            .then(res => {
                this.cityData = res.data.values;
                this.schoolData = [];
            });
        },
        getSchool(){
            let cityID = this.form.cityID;
            axios
            .get("https://backend-external.uss-utbk.com/schooldropdown",{
                params: {
                    cityID: cityID
                }
            })
            .then(res => {
                this.schoolData = res.data.values;
            });
        },
        toPage(param) {
            this.$router.push(param);
        },
        displayMessage(param){
            this.registerMessage = param;
            this.$root.$emit("bv::show::modal", "modal");
        }
    }
};
</script>


<style scoped>

.body {
  background-color: #F3F7FA;
}

.si-form {
    border: 1px solid rgb(212, 212, 212);
    width: 360px;
    padding-left:10px;
}
.spnd-blue {
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    width:140px;
    height:100%;
    line-height:32px;
    color: #FFF;
    font-size: 12px;
    text-align: left;
    background-color: #3B73C5;
}

.stxb{
    padding:0px;
    margin:0px;
}
.stxb input{
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
    border:1px solid #cfcfcf;
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: #3B73C5;
    padding-left:10px;
}
.stxb .bv-input{
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
    border:1px solid #cfcfcf;
    width: 100%;
    margin:0px;
    padding:0px;
    font-size: 12px;
    color: #3B73C5;
    padding-left:10px;
}
.stxb .bv-input:focus{
    outline: none;
    border:none;
}
.stxb input:focus{
    border:1px solid #3B73C5;
    outline: none;
}
.sbg-blue {
  background-color: #3B73C5;
}

.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
.sc-textbox {
    border-radius:10px;
    color: #3B73C5;
    margin-bottom:10px;
}

.sc-textbox:focus {
    border-radius:10px;
    color: #3B73C5;
}
.wrap-info .row {
    margin-bottom:10px;
}
</style>