<template>
    <div>
            <div class="mb-4" style="background-color:#FFFF00;font-size:14px;border-bottom:2px solid #2E3091;">
                <b-container>
                    <b-navbar toggleable="lg">
                        <b-navbar-brand>
                            <img width="100" src="../assets/logosg.png"/>
                        </b-navbar-brand>
                    </b-navbar>
                </b-container>
            </div>
            <!-- <div  class="mb-4" style="background-color:#FFFF00;font-size:14px;border-bottom:2px solid #2E3091">
                <b-container>
                    <b-navbar toggleable="lg">
                        <b-navbar-brand>
                            <img width="100" src="../assets/logosg.png"/>
                        </b-navbar-brand>

                        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                        <b-collapse id="nav-collapse"  is-nav>
                            <b-navbar-nav >
                                <b-nav-item @click="toPage('/homepage')">Beranda</b-nav-item>
                                <b-nav-item @click="toPage('/transaction')">Transaksi</b-nav-item>
                                <b-nav-item @click="toPage('/administration')">Administrasi</b-nav-item>
                            </b-navbar-nav>

                        <b-navbar-nav class="ml-auto">
                            <b-row align-v="center">
                                    <img class="rounded-circle" height="40" 
                                    src="https://fydn.imgix.net/m%2Fpod-artwork%2Fpool%2F275b2df20013254c65bae217d425ef22-pink-butterfly-1-art-print-P1.jpg?auto=format%2Ccompress&q=75"/>
                                    <b-nav-item-dropdown variant="bg-dark"  right>
                                        <template v-slot:button-content>
                                            {{display.fullName}}
                                        </template>
                                        <b-dropdown-item style="font-size:14px" @click="toPage('/profile')" size="sm">Profil</b-dropdown-item>
                                        <b-dropdown-item style="font-size:14px" @click="toPage('/')" size="sm">Keluar</b-dropdown-item>
                                    </b-nav-item-dropdown>
                            </b-row>
                        </b-navbar-nav>
                        </b-collapse>
                    </b-navbar>
                </b-container>
                
            </div> -->
    </div>
</template>

<style scoped>
    .test {
        color: #FFF;
    }
</style>
<script>
import axios from "axios"
export default {
    methods:{
        toPage(param) {
            this.$router.push(param);
            this.currentRoute = (this.$router.currentRoute.path)
        }
    },
    data(){
        return {
            currentRoute: (this.$router.currentRoute.path),
            display: {
                fullName: ''
            }
        }
    },
    created(){
        let userID = localStorage.getItem("userID");
        if (userID != null){
            axios
            .get("https://backend-external.uss-utbk.com/user",{
                params: {
                    userID: userID
                }
            })
            .then(res => {
                let userData = res.data.values;
                this.display.fullName = userData[0].userFullName;
            });
        }
    }
}
</script>