import Vue from "vue";
import VueRouter from "vue-router";
import Entrance from "../views/Entrance.vue";
import Homepage from "../views/Homepage.vue";
import NewHomepage from "../views/NewHomepage.vue";
import Profile from "../views/Profile.vue";
import Transaction from "../views/Transaction.vue";
import Administration from "../views/Administration.vue";
import Exam from "../views/Exam.vue";
import Registration from "../views/Registration.vue";
import AdminDashboard from "../views/AdminDashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Entrance",
    component: Entrance
  },
  {
    path: "/homepage",
    name: "Homepage",
    component: Homepage
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/transaction",
    name: "Transaction",
    component: Transaction
  },
  {
    path: "/administration",
    name: "Administration",
    component: Administration
  },
  {
    path: "/exam",
    name: "Exam",
    component: Exam
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration
  },
  {
    path: "/admindashboard",
    name: "AdminDashboard",
    component: AdminDashboard
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
